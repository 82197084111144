import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import madeline from '../images/madeline.jpg'
import './index.scss';

const IndexPage = () => (
  <Layout>
    <div className="page-layout home-page">
      <div className="container">
        <img className="home-page__hero-image" src={madeline} />
        <div>
          <h1 class="home-page__title">Madeline Sipp</h1>
          <p>
            Madeline Sipp was born in Orange County, California. She currently resides in Lincoln, 
            Nebraska and is active as an artist/model. Madeline's art currently focuses on the creation 
            of collage. Madeline intertwines vintage erotica with pop culture images and advertisement; 
            to create one of a kind images which challenge the viewer to reexamine society's constraints 
            on women, nudity, and objectification of the body.
          </p>
          <p>
            Madeline's current collection will be offered for a limited time. To purchase a print, 
            please view our Collection. To purchase a one-of-a-kind original or commission an original 
            piece, please contact Madeline directly. 
          </p>
          <button>Prints</button>
          <button>Merch</button>
        </div>
      </div>
    </div>
    
  </Layout>
)

export default IndexPage
